<template>
    <div class="free_course">
        <div class="back_title">
            支付
        </div>
        <div class="overFlow_box">
            <div class="pay_msg_box">
                <span>￥<i>{{Number(price)}}</i></span>
                <span>商品名称</span>
                <span>亿健魔镜会员{{name}}</span>
            </div>
            <div class="btn_box">
                 <!-- <h1 class="tradeno">点击以下按钮唤起收银台支付</h1>
                <a href="javascript:void(0)" class="btn tradenoPay">支付宝交易号唤起支付</a>     -->
                <van-button type="default" class="tradeno" >继续支付</van-button>
                <!-- <span class="pay_resault" @click="goResaultPage()">查询支付结果</span> -->
            </div>
        </div>
 
        
    </div>
</template>
<script src="https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js"></script>
<script>

import { Toast } from 'vant';
    export default {
        data () {
            return {
                userOrder: '', //用户订单
                tradeNO: '', //授权成功后获取的订单编号
                //订单信息
                    scope: 'auth_base', //获取授权的scope
                    redirect_uri: '', //回调的url
                    response_type: '', //获取微信授权的response_type
                //用户订单信息
                    price: 0.00,
                    name: '',
                    time: '',
                    wxData: {},
                times: '',

                
            }
        },
        created() {
           // this.checkIsPay()
        },
        mounted() {
            this.checkIsPay()
        },
        methods: {
          
            checkIsPay() {
                //未支付
                if (navigator.userAgent.indexOf("AlipayClient") > 0) {
                    this.checkIsAuthCode()
                } else if (navigator.userAgent.indexOf("MicroMessenger") > 0) {
                    this.checkIsAuthCodeWx()
                }else{
                    Toast('请使用微信或者支付宝支付')
                    return;
                }
            },
            //判断是否有auth_code
            checkIsAuthCode() {
                if(window.location.href.split('auth_code')[1]) {
                    //如果有，说明已经授权过，直接获取auth_code调用获取支付宝订单信息接口
                    let authCode = ''
                    let params = new URLSearchParams(window.location.search);
                    this.userOrder = params.get('order_no')
                    authCode = params.get('auth_code')
                    this.pay(authCode, this.userOrder)
                } else {
                    //如果没有判断当前url是否有用户订单信息
                    if(window.location.href.split('order_no')[1]) {
                        //如果有获取用户订单信息拼接到回调的url后面
                        this.redirect_uri = window.location.href
                        this.goAlipaySilentAuthorization()
                    } else {
                        //如果没有弹出错误信息
                         Toast('订单信息可能有误！')
                        
                    }
                }
            },
            //调转到支付宝的静默授权地址
            goAlipaySilentAuthorization() {
                //const Timestamp = new Date().getTime();
                window.location.href = (`https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021002135678730&redirect_uri=${encodeURI(this.redirect_uri)}&scope=${this.scope}&state=12345`); 
            },           
            //通过获取的auth_code和用户订单信息调取后端接口获取支付宝订单信息
            pay(authCode, userCode) {
                this.$http.formData(this.$api.getApiAddress('member/order/create/alipay'), {
                    auth_code: authCode,
                    order_no: userCode
                    
                }).then((res) => {  
                    if(res.data.code == 200) {
                        if(res.data.data.status === 3) {
                            this.$router.push({path: '/getResults'})
                        } else if(res.data.data.status === 4) {
                           Toast('支付超时，请重新扫码！');
                        } else if(res.data.data.status === 2) {
                            this.name = res.data.data.sku_name
                            this.price = res.data.data.pay_price
                            sessionStorage.setItem('tradeNO',res.data.data.pay_info.body);
                            this.ready(this.readyPay)
                        }
                    } 
                })
            },
            //支付宝支付
            ready(callback) {
                // 如果jsbridge已经注入则直接调用
                let _this = this
                if (window.AlipayJSBridge) {
                    AlipayJSBridge.call("tradePay", {
                        tradeNO: sessionStorage.getItem('tradeNO')
                    }, 
                    function(result) {
                        if(result.resultCode == '9000') {
                            _this.$router.push({path: '/getResults'})
                         } else  {
                            //alert(JSON.stringify(result));
                         }
                    });
                    callback && callback();
                } else {
                    document.addEventListener('AlipayJSBridgeReady', callback, false);
                }
            },
            readyPay() {
                let _this = this
                document.querySelector('.tradeno').addEventListener('click', function() {
                    AlipayJSBridge.call("tradePay", {
                        tradeNO: sessionStorage.getItem('tradeNO')
                    }, function(result) {
                        if(result.resultCode == '9000') {
                            _this.$router.push({path: '/getResults'})
                         } else  {
                           // alert(JSON.stringify(result));
                         }
                    });
                });
            },



            checkIsAuthCodeWx() {
                let paramsUrl = new URLSearchParams(window.location.search);
                if(paramsUrl.get('code')) {
                    //如果有，说明已经授权过，直接获取auth_code调用获取支付宝订单信息接口
                    let authCode = ''
                    let params = new URLSearchParams(window.location.search);
                    this.userOrder = params.get('order_no')
                    //alert(this.userOrder)
                    authCode = params.get('code')
                    this.payWx(authCode, this.userOrder)
                    // var u = navigator.userAgent;
                    // var device =""; //当前设备信息
                    // if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {//安卓手机
                    //      window.history.go(-1)
                    // } else if (u.indexOf('iPhone') > -1) {//苹果手机
                    //    // d = "iPhone";
                    // } else if (u.indexOf('Windows Phone') > -1) {//winphone手机
                    //     //device = "Windows Phone";
                    // }
                   
                } else {
                    //如果没有判断当前url是否有用户订单信息
                    if(window.location.href.split('order_no')[1]) {
                        //如果有获取用户订单信息拼接到回调的url后面
                        this.redirect_uri = window.location.href
                        this.goWxSilentAuthorization()
                    } else {
                        //如果没有弹出错误信息
                         Toast('订单信息可能有误！')
                        
                    }
                }
            },

            goWxSilentAuthorization() {
               
                window.location.href = (`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb0ea08fe3f56cfae&redirect_uri=${(this.redirect_uri)}&response_type=code&scope=snsapi_base&connect_redirect=1#wechat_redirect`)
            },

            payWx(authCode, userCode) {
                this.$http.formData(this.$api.getApiAddress('member/order/create/wechat'), {
                    auth_code: authCode,
                    order_no: userCode
                    
                }).then((res) => {  
                     console.log(res.data.data.pay_info.body,'res.datares.datares.data')
                    if(res.data.code == 200) {
                        if(res.data.data.status === 3) {
                            this.$router.push({path: '/getResults'})
                        } else if(res.data.data.status === 4) {
                           Toast('支付超时，请重新扫码！');
                        } else if(res.data.data.status === 2) {
                            this.name = res.data.data.sku_name
                            this.price = res.data.data.pay_price
                            sessionStorage.setItem('tradeNO',res.data.data.pay_info.body);
                            this.wxData = res.data.data.pay_info.body
                            setTimeout(()=> {
                                this.readyWx(JSON.parse(sessionStorage.getItem('tradeNO')))
                            }, 800)
                            //this.onBridgeReady(JSON.parse(sessionStorage.getItem('tradeNO')))
                           //this.readyWx(JSON.parse(sessionStorage.getItem('tradeNO')))
                        }
                    } 
                })
            },

            readyWx(callback) {
               
                // 如果jsbridge已经注入则直接调用
                let vm = this
                if (typeof WeixinJSBridge == "undefined"){
                   
                    if( document.addEventListener ){
                        document.addEventListener('WeixinJSBridgeReady', vm.onBridgeReady(callback), false);
                    }else if (document.attachEvent){
                        document.attachEvent('WeixinJSBridgeReady', vm.onBridgeReady(callback));
                        document.attachEvent('onWeixinJSBridgeReady',vm.onBridgeReady(callback));
                        
                    }
                }else{
                    vm.onBridgeReady(callback);
                }
                document.querySelector('.tradeno').addEventListener('click', function() {
                     console.log('aaaaaaaaaaaaaaaaaaaaaa')
                   if (typeof WeixinJSBridge == "undefined"){
                   
                        if( document.addEventListener ){
                            document.addEventListener('WeixinJSBridgeReady', vm.onBridgeReady(callback), false);
                        }else if (document.attachEvent){
                            document.attachEvent('WeixinJSBridgeReady', vm.onBridgeReady(callback));
                            document.attachEvent('onWeixinJSBridgeReady',vm.onBridgeReady(callback));
                            
                        }
                    }else{
                        vm.onBridgeReady(callback);
                    }
                });
            },


            onBridgeReady(data) {
                if (typeof WeixinJSBridge === 'undefined') {
                   Toast({ message: '请使用微信内置浏览器进行支付' })
                } else {
                    WeixinJSBridge.invoke(
                        'getBrandWCPayRequest',
                        {
                            appId: data.appId, // 公众号名称，由商户传入
                            timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
                            nonceStr: data.nonceStr, // 随机串
                            package: data.package,
                            signType: data.signType, // 微信签名方式：
                            paySign: data.paySign // 微信签名
                        },
                        res => {
                          //  Toast(res )
                            if (res.err_msg === 'get_brand_wcpay_request:ok') {
                               Toast({ message: '支付成功' })
                               this.$router.push({path: '/getResults'})
                                //this.$router.push({path: '/videoplayer', query: { video_id: this.$route.query.video_id }}) // 支付成功之后跳转的路由
                            } else {
                                //Toast({ message: '支付失败111' })
                            }
                        }
                    )
                } 
            },

        }
    }
</script>
<style  lang='scss' scoped>
.free_course {
    //width: calc(100% - 24px);
    background:  #ffffff;
    height: 100%;
    overflow-y: auto;
    .back_title {
        width: 100%;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 34px;
        font-weight: 600;
        color: #000000;
        position: relative;
        background: #ffffff;
        i {
            position: absolute;
            left: 24px;
            color: #000000;
        }
    }
    .overFlow_box {
        background: #ffffff;
        //height: 100%;
        .pay_msg_box {
            span {
                display: block;
                width: 100%;
                text-align: center;
            }
            span:nth-child(1) {
                font-size: 60px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 600;
                color: #333333;
                margin-top: 66px;
            }
            span:nth-child(2) {
                font-size: 32px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #313131;
                margin-top: 34px;
            }
            span:nth-child(3) {
                font-size: 32px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #969696;
                margin-top: 6px;
            }

        }
        .btn_box {
            // margin-left: 50%;
            // transform: translate(-50%);
            width: 100%;
            position: absolute;
            bottom: 40px;
            .van-button {
                width: 90%;
                display: block;
                margin-left: 50%;
                transform: translate(-50%);
                height: 48px;
                background: #333333;
                border-radius: 6px;
                font-size: 15px;
                font-family: STHeitiSC-Medium, STHeitiSC;
                font-weight: 500;
                color: #FFFFFF;
            }
            .pay_resault {
                display: block;
                width: 100%;
                text-align: center;
                margin-top: 14px;
                font-size: 28px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #969696;
                cursor: pointer;
            }
        }
    }
 
}

</style>